























































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { User } from '@/api/interfaces/user'
import store from '@/store'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import { PaginationOptions, parseQuery, usePagination } from '@/composables/pagination'
import { QueryParams } from '@/store/api-plateform-utils'

export default defineComponent({
  setup (props, ctx) {
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.userList.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      }: PaginationOptions = usePagination(
      totalItems,
      parseQuery(query),
    ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
    }
  },
  name: 'ListAssociateUser',
  components: {
    TopBar,
    Pagination,
  },
  data () {
    return {
      title: this.$t('user.list.title'),
      headers: [
        {
          value: 'checkbox',
          sortable: false,
        },
        {
          text: this.$t('user.list.headers.email'),
          align: 'start',
          value: 'email',
        },
        {
          text: this.$t('user.list.headers.lastName'),
          align: 'start',
          value: 'lastName',
        },
        {
          text: this.$t('user.list.headers.firstName'),
          value: 'firstName',
        },
      ],
      isSubmiting: false,
      checkedUsers: [] as string[],
      loadedUsers: new Map<string, User>(),
    }
  },
  watch: {
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  created () {
    if (this.userGroup?.users) {
      this.checkedUsers = this.userGroup.users.map((user: { id: any }) => user.id)
      this.userGroup.users.forEach((u: User) => {
        this.loadedUsers.set(u.id as unknown as string, u)
      })
    }
    this.load(this.baseRequest).then(this.afterLoad)
  },
  computed: {
    ...mapState('userGroupForm', {
      userGroup: 'userGroup',
    }),
    ...mapState('userList', {
      list: 'list',
      loading: 'loading',
    }),
    ...mapGetters('navigation', {
      getLastRouteUserGroup: 'getLastRouteUserGroup',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  methods: {
    ...mapMutations('userGroupForm', {
      setUsers: 'setUsers',
    }),
    ...mapActions('userList', {
      load: 'load',
    }),
    ...mapActions('userGroupForm', {
      resetUserGroup: 'reset',
    }),
    afterLoad (loadedList: User[]) {
      loadedList.forEach((u: User) => {
        this.loadedUsers.set(u.id as unknown as string, u)
      })
    },
    loadData () {
      this.load(this.baseRequest).then(this.afterLoad)
    },
    associateCheckedUsers () {
      this.isSubmiting = true
      const users = [] as User[]
      this.checkedUsers.forEach(userId => {
        if (this.loadedUsers.has(userId)) {
          users.push(this.loadedUsers.get(userId) as User)
        }
      })

      this.setUsers(users)

      this.$router.push(this.getLastRouteUserGroup)
    },
  },
  beforeRouteEnter (to, from, next) {
    store.commit('navigation/addRoute', to)
    next()
  },
  /*
   */
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'UserGroup Create') {
      store.commit('navigation/removeRoute')
    }
    if (to.name !== 'UserGroup Edit' && to.name !== 'UserGroup Create') {
      this.resetUserGroup()
    }
    next()
  },
})
